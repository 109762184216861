import React, { useState } from "react"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Divider from '@mui/material/Divider';

import IshSlider from "./IshSlider"
import Evaluation from "./Evaluation"

const SliderList = ({ page }: any) => {
    const allSliders: Slider[] = page.groups.flatMap((group: Group) => group.sliders);
    const initialSliderValues = Object.fromEntries(allSliders.map((slider: any) => [slider.shorthand ?? slider.title, 0]))
    const [allSliderValues, setAllSliderValues] = useState<any>(initialSliderValues)

  const handleSliderValueChange = (key: number, value: number) => {
    setAllSliderValues({ ...allSliderValues, [key]: value })
  }

  const groupsAndSliders = page.groups.map((group: any, group_index: number) => {
    const groupName =
    group.name
      ?
        <Divider role="presentation"
          sx={{
            "&::before, &::after": {
              borderColor: "var(--secondary-light)",
            },
            color: "white",
            fontFamily: "Rubik",
            marginTop: "50px",
            maxWidth: "650px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
        {group.name}
        </Divider >
      :
         ""

    const groupDescription = group.description ? <p> {group.description} </p> : ""

    return (
      <>
        {groupName}
        {groupDescription}
        {group.sliders.map((slider: any, slider_index: number) => (
          <div key={slider_index} className="ishSliderContainer">
            <IshSlider
              id={`${slider.title}`}
              slider={slider}
              sendValueUp={handleSliderValueChange}
            />
          </div>
        ))
        }

      </>

    )
  }
  )

  return (
    <>
      <h1 className="page-header"> {page.header} </h1>
      <p className="page-description"> {page.description} </p>

      {groupsAndSliders}

      {page.evaluation.type == "none" ? "" : <Evaluation allSliderValues={allSliderValues} page={page} />}

      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignContent: "center",
          justifyContent: "center",
          marginTop: "80px",
        }}
      >
        <Button className="legal-button" variant="outlined" href="/impressum">
          Impressum{" "}
        </Button>
        <Button className="legal-button" variant="outlined" href="/datenschutz">
          Datenschutz
        </Button>
        <Button className="legal-button" variant="outlined" href="https://t11388d93.emailsys1a.net/106/5997/164be38925/subscribe/form.html?_g=1670492756">
          Newsletter
        </Button>

      </Stack>
    </>
  )
}
export default SliderList
