import _ from "lodash";
import React, { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Alert from '@mui/material/Alert';

export default function IshSlider({ slider, sendValueUp, id }: any) {
  const [sliderValue, setSliderValueInState] = useState<any>(0)
    
  const sliderDescription = slider.description ? <p className="slider-description"> {slider.description} </p> : ""
  const sliderNote = slider.note ? <Alert className="slider-note" severity="warning"> {slider.note} </Alert> : ""

  return (
    <>
      <h2 className="slider-heading"> {slider.title} </h2>
      {sliderDescription}
      {sliderNote}

      <Box className="slider-wrapper-box">
        <Slider
          aria-label="Raum Slider"
          defaultValue={0}
          step={1}
          track={false}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => slider.values[value][0]
          }
          onChange={(event, value) => {
            setSliderValueInState(value)
            sendValueUp(id, value)
          }}
          onChangeCommitted={(event, value) => {
            setSliderValueInState(value)
            sendValueUp(id, value)
          }}
          marks={true}
          min={0}
          max={_.size(slider.values) - 1}
        />

        <Card
          sx={{
            bgcolor: "#fff",
            marginTop: "5px",
          }}
        >
          <CardContent sx={{}}>
            <Typography sx={{ fontSize: 16 }} gutterBottom>
              {slider.values[sliderValue][1]}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
