import React from 'react'

export default function CustomGridLabel(props: any) {
	const anchor = props.x > 5 ? "start" : props.x < -5 ? "end" : "middle";

	return (
		<g transform={`translate(${props.x}, ${props.y})`}>
			<text alignmentBaseline={"middle"} textAnchor={anchor} fontSize={13}>
				{props.id}
			</text>
		</g>
	)
}


