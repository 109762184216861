import React from 'react'
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"


export default function WelcomePage() {
	return (
		<>
		<Box sx={{ display: { xs: "block", md: "none" }, marginBottom:"50px" }}>
		  <h1 className="page-title"> Didaktische Schieberegler </h1>
		  <h5 className="page-subtitle"> Didaktisch. Praktisch. Gut. </h5>
		</Box>
		

		
		<div className="page-header">
			<h2> Herzlich willkommen! </h2>

			{/* <h6>
				Klicken Sie links auf eine Kategorie, um mit den Schiebereglern zu arbeiten.
			</h6> */}
			<p className="">
				Die didaktischen Schieberegler fußen auf einer Idee von Axel Krommer, Philippe Wampfler und Wanda Klee. Die Entwicklung eines interaktiven Schiebereglers haben u. a. Bob Blume und Carsten Quabeck umgesetzt. Wir haben die Idee auf verschiedene Bereiche erweitert und ein digitales Tool entwickelt.
				</p>
				<p>
				Die Schieberegler können als Planungs-, Diskussions-, Reflexions- oder Auswertungstool fungieren. Wir haben sie als ‚work in progress‘ für unsere eigenen Fortbildungsveranstaltungen und Qualifizierungsreihen angelegt. Sie erheben daher weder Anspruch auf Vollständigkeit noch auf Eindeutigkeit und werden fortwährend (gerne auch unter Einbezug Ihrer Rückmeldungen) angepasst und weiterentwickelt.
				</p>
				<p>
				Die Schieberegler sind unserer Erfahrung nach eine visuelle Hilfestellung für komplexe Skalierungsfragen. Und auch wenn es sicherlich nicht immer möglich ist, sich oder seine Veranstaltung eindeutig auf einer Schiebereglerposition zu verorten, so bringt der Auseinandersetzungsprozess mit mancher Frage doch eine Tendenzantwort oder verfolgenswerte neue Impulse mit sich.
				</p>
				
			<p className="">
				Die didaktischen Schieberegler sind ein browserbasiertes,
				datenschutzkonformes und kostenfreies Tool der ISH Manufaktur. Die ISH
				Manufaktur ist Teil der ISH Gruppe. Nähere Infos zum ISH finden Sie
				unter <a href="https://ish-gruppe.de">ish-gruppe.de</a>.
			</p>
			<p>
				Sie möchten mehr über unsere Tools und unser Institut erfahren? Dann tragen Sie sich <a href="https://t11388d93.emailsys1a.net/106/5997/164be38925/subscribe/form.html?_g=1670492756"> hier für unseren Newsletter </a> ein! 
			</p>
			<Stack
				direction="row"
				spacing={2}
				sx={{
					alignContent: "center",
					justifyContent: "center",
					marginTop: "80px",
				}}
			>
				<Button className="legal-button" variant="outlined" href="/impressum">
					Impressum{" "}
				</Button>
				<Button className="legal-button" variant="outlined" href="/datenschutz">
					Datenschutz
				</Button>
				<Button className="legal-button" variant="outlined" href="https://t11388d93.emailsys1a.net/106/5997/164be38925/subscribe/form.html?_g=1670492756">
					Newsletter
				</Button>
			</Stack>

		</div>
		</>
	)
}
