import React, { useState } from 'react'
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import SpiderChart from "./SpiderChart"

export default function Evaluation({ allSliderValues, page }: any) {

	const evaluationText = () => {
		let sum = 0
		for (let key in allSliderValues) {
			sum += allSliderValues[key] ?? 0
		}

		for (let criterion of page.evaluation.criteria) {
			if (sum <= criterion.score) {
				return criterion.interpretation
			}
		}
		return "Fehler. Auswertung konnte nicht berechnet werden.";
	}


	function generateEvaluation() {
		if (page.evaluation.type === "text") {
			return (<Typography sx={{ fontSize: 16 }} gutterBottom>
				{evaluationText()}
			</Typography>)
		}
		else if (page.evaluation.type === "spider") {
			return (<SpiderChart page={page} allSliderValues={allSliderValues} />
			)
		}
		else if (page.evaluation.type === "both") {
			return (
				<>
					<Typography sx={{ fontSize: 16 }} gutterBottom>
						{evaluationText()}
					</Typography>
					<SpiderChart page={page} allSliderValues={allSliderValues} />
				</>
			)
		} else {
			return "Für diese Schieberegler ist keine Auswertung angedacht."
		}
	}

	const copyrightNote = () => {
		if (page.header ==  "Kapazität für Implementation") {
		return (<Typography variant="caption" display="block" align="right">
			Copyright HG Rolff
		  </Typography>)
	}
	}

	const evaluationSubtitle = () => {
		if (page.header ==  "Kapazität für Implementation") {
		return (<Typography variant="body1" display="block" align="center">
			Einschätzung der Kapazität für Entwicklung
		  </Typography>)
	}
	}

	return (
		<>
			<h1 className="slider-heading"> Auswertung </h1>
			<Card
			className="evaluation-container">
				<CardContent sx={{}}>
					{generateEvaluation()}		
					{copyrightNote()}			
				</CardContent>
			</Card>
			{evaluationSubtitle()}

		</>
	)
}