import React from "react";
import { ResponsiveRadar } from "@nivo/radar";
import CustomGridLabel from "./CustomGridLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function SpiderChart({ page, allSliderValues }: {page: Page, allSliderValues: sliderValueObject}) {
  const sliderValuesAsArray = Object.entries(allSliderValues).map(
    (entry: Array<any>) => ({ slider: entry[0], Wert: entry[1] + 1 })
  );

  const maxSliderValue = Object.keys(page.groups[0].sliders[0].values).length;

  return (
    <>
      <Box
        sx={{
          height: { xs: "230px", md: "340px" },
          minWidth: { xs: "300px", sm: "auto" }
        }}
      >
        <ResponsiveRadar
          data={sliderValuesAsArray}
          keys={["Wert"]}
          indexBy="slider"
          margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
          gridLabelOffset={10}
          gridLevels={maxSliderValue}
          maxValue={maxSliderValue}
          gridLabel={CustomGridLabel}
          dotSize={10}
          fillOpacity={0.5}
          isInteractive={false}
          gridShape="linear"
          animate={false}
          enableDotLabel={false}
          colors={{ scheme: "pastel2" }}
        />
      </Box>
    </>
  );
}
