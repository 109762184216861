import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import manufaktur_logo from "../assets/ISH_Bochum_Logo_Manufaktur_bw_sm.png"



export default function MenuBar({toggleDrawer, toggleDonationModal}: {toggleDrawer: () => void, toggleDonationModal: () => void}) {
	return (
			<AppBar
				position="fixed"
				sx={{
					backgroundColor: "var(--bg-color)"
				}}
			>
				<Toolbar disableGutters sx={{ display: "flex" }}>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={toggleDrawer}
						sx={{ mr: "20px", ml: "20px", position: "absolute" }}
					>
						<MenuIcon />
					</IconButton>
					
					<Box
						sx={{
							height: { xs: "40px", md: "50px" },
							margin: { xs: "auto", md: "0" },
							justifySelf: { xs: "center", md: "start" },
							width: { xs: "auto", md: "200px" },
						}}
					>
						<a href="/" id="logo-link">
							<img id="logo" src={manufaktur_logo}></img>
						</a>
					</Box>
			
					<Box
						sx={{
							display: { xs: "none", md: "flex" },
							margin: "auto",
							justifySelf: "center",
						}}
					>
						<h3>Didaktische Schieberegler</h3>
					</Box>
			
					<Box
						sx={{
							display: { xs: "none", md: "flex" },
							marginRight: "20px"
						}}
					>
					
						<Button
						variant="outlined"
						color="inherit"
						startIcon={<FavoriteBorderIcon />}
						onClick={toggleDonationModal}
							>
							Spenden 
						</Button>
					</Box>
			
				</Toolbar>
			</AppBar>
	)
}
