import React from "react"
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"

import Box from "@mui/material/Box"

import "./App.css"
import SliderList from "./components/SliderList"
import MenuList from "./components/MenuList"
import Impressum from "./components/Impressum"
import Datenschutz from "./components/Datenschutz"
import WelcomePage from "./components/WelcomePage"

import { Content, Page, Group, Slider } from "./Interfaces"
import { replaceUmlaute } from "./components/HelperFunctions"

import CONTENT from "./sliders.json"



function App() {
  const pagesJson = CONTENT[0].pages

  const pageRoutes = pagesJson.map(page =>
    <Route
      path={replaceUmlaute(page.header.split(" ").join("_").toLowerCase())}
      element={<SliderList page={page} groups={page.groups} pageHeader={page.header} />}>
    </Route>
  )

  const pageRoutePaths = pageRoutes.map(route => route.props.path)

  const drawerWidth = 240;

  return (
    <Router>
      <header className="app-header">
        <MenuList pagesJson={pagesJson} pageRoutes={pageRoutePaths} drawerWidth={drawerWidth} />
      </header>

      <div className="app">
        <Box
          component="main"
          className="content"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginLeft: {sm: `${drawerWidth}px`}}}
        >
        
          <Routes>
            {pageRoutes}
            <Route path="/" element={<WelcomePage />}></Route>
            <Route path="/impressum" element={<Impressum />}></Route>
            <Route path="/datenschutz" element={<Datenschutz />}></Route>
          </Routes>
        </Box>
      </div>
    </Router>
  )
}

export default App
