import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import DonationModal from "./DonationModal"
import MenuBar from "./MenuBar"

import { Content, Page, Group, Slider } from "../Interfaces"


export default function ResponsiveDrawer({ pagesJson, pageRoutes, drawerWidth }: any) {
	const [drawerOpen, setDrawerOpen] = React.useState(false)
	const toggleDrawer = () => { setDrawerOpen(!drawerOpen) }
	
	const [donationModalOpen, setDonationModalOpen] = React.useState(false)
	const toggleDonationModal = () => { setDonationModalOpen(!donationModalOpen) }

	const pageHeadings = pagesJson.map((pageObject: Page) => pageObject.header)

	  const [mobileOpen, setMobileOpen] = React.useState(false);
	
	  const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	  };


	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List>
					<ListItem key="home" disablePadding>
				<ListItemButton href={"/"}>
					<ListItemText sx={{ color: "white" }} primary={"Startseite"} />
				</ListItemButton>
			</ListItem>
			
				{pageHeadings.map((text: string, index: number) => (
					<ListItem key={text} disablePadding>
						<ListItemButton href={"/" + pageRoutes[index]}>
							<ListItemText sx={{ color: "white" }} primary={text} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</div >
	);
	

	return (
		<Box sx={{ display: 'flex', backgroundColor: "var(--bg-color)" }}>
			<MenuBar 
			toggleDrawer={toggleDrawer}
			toggleDonationModal={toggleDonationModal}
			/>
			
			<Drawer
			  variant="temporary"
			  id="temp-drawer"
			  open={drawerOpen}
			  onClose={toggleDrawer}
			  ModalProps={{
				keepMounted: true, // Better open performance on mobile.
			  }}
			  sx={{
				display: { xs: 'block', sm: 'none' },
				'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor:"var(--bg-color)", borderRight: "1px solid #d9d9d966"},
			  }}
			>
			  {drawer}
			</Drawer>
			<Drawer
			  variant="permanent"
			  id="permanent-drawer"
			  sx={{
				display: { xs: 'none', sm: 'block' },
				'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor:"var(--bg-color)", borderRight: "1px solid #d9d9d966", },
			  }}
			  open
			>
			  {drawer}
			</Drawer>

			<Toolbar />
				
			<DonationModal open={donationModalOpen} handleClickOpen={toggleDonationModal} handleClose={toggleDonationModal}/>
		</Box >
	);
}