const Impressum = () => {
  return (
    <div className="legal-text">
      <h1>Impressum & Kontakt </h1>
      <h6>
        Klicken Sie auf das ISH-Logo, um zurück zur Startseite zu gelangen.
      </h6>
      <p>
        Die didaktischen Schieberegler sind ein browserbasiertes,
        datenschutzkonformes und kostenfreies Tool der ISH Manufaktur. Die ISH
        Manufaktur ist Teil der ISH Gruppe. Nähere Infos zum ISH finden Sie
        unter <a href="https://ish-gruppe.de">ish-gruppe.de</a>.
      </p>
      <h2>Anbieter</h2>
      <p>
        <b>Institut für Schulentwicklung und Hochschuldidaktik GmbH</b> <br />
        Dennis Sawatzki <br /> Schadowstraße 34 <br />
        44799 Bochum
      </p>
      <h2>Kontakt</h2>
      Website: <a href="https://ish-gruppe.de/">https://ish-bochum.de</a>
      <br />
      E-Mail: <a href="mailto:office@ish-gruppe.de">
        office@ish-bochum.de
      </a>{" "}
      <br />
      Telefon: 0234 / 5457 4111
    </div>
  )
}
export default Impressum
